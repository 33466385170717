<template>
  <b-row>
    <confirm-modal
      id="close-manifest-modal"
      :v-if="confirmCloseManifest"
      title="Are you sure you want to proceed?"
      :confirmation="confirmManifestText"
      :ok-button="confirmManifestButton"
      :loading="manifestLockLoading"
      @submit="lockManifests(true)"
    />
    <b-col v-if="isLoaded">
      <b-card
        class="shipment-view"
        title="Manifest information"
      >
        <b-row
          v-if="!isPreDeclarationDone && canEditManifest"
          class="pb-20"
        >
          <b-col class="col-12">
            <b-form-file
              ref="fileHandle"
              v-model="uploadFile"
              placeholder="Select a manifest file (XLSX, CSV)"
              drop-placeholder="Drop file here..."
              class="b-custom-control-xl"
            />
          </b-col>
        </b-row>

        <b-row
          v-if="showProgress()"
          class="pb-20"
        >
          <b-col class="col-4">
            <b-progress
              ref="fileProgress"
              :value="upload.uploadProgress"
              max="100"
            />
          </b-col>
        </b-row>

        <b-row
          v-if="!isPreDeclarationDone"
          class="pb-20"
        >
          <b-col>
            <b-card-text>
              <span>Take a look at the manifest <a :href="getTemplate">template</a>.</span>
            </b-card-text>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="col-12 text-align:left;">
            <b-table
              :fields="fields"
              :items="shipment.files"
              thead-class="d-none"
              class="files-table"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <p class="m-0">
                    You don't have uploaded manifests yet
                  </p>
                </div>
              </template>
              <template #cell(data)="data">
                <div
                  class="d-flex align-items-center"
                >
                  <div>
                    <div
                      v-if="data.item.state.status !== 'removed'"
                      class="font-weight-bolder"
                    >
                      <b-link :href="`${fileBaseUrl}/files/${data.item.id}`">
                        {{ $truncate(data.item.state.filename, 30) }}
                      </b-link>
                    </div>
                    <div
                      v-if="data.item.state.status === 'removed'"
                      class="font-weight-bolder"
                    >
                      {{ data.item.state.filename }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(state.status)="data">
                <div
                  v-if="data.item.state.status === 'queued'"
                  class="statistics text-warning"
                >
                  Queued
                </div>
                <div
                  v-if="data.item.state.status === 'failed'"
                  class="statistics text-danger"
                >
                  Failed
                </div>
                <div
                  v-if="data.item.state.status === 'removed'"
                  class="statistics text-danger"
                >
                  Removed
                </div>
                <div
                  v-if="data.item.state.status === 'repair'"
                  class="statistics text-info"
                >
                  Repaired
                </div>
                <div
                  v-if="data.item.state.status === 'processing'"
                  class="statistics text-info"
                >
                  <span v-if="data.item.state.progress">
                    Importing {{ data.item.state.rowsTotal || 0 }} rows...
                  </span>
                  <span v-else>Reading manifest...</span>
                </div>
                <b-progress
                  v-if="data.item.state.progress && data.item.state.status === 'processing'"
                  v-model="data.item.state.progress"
                  max="100"
                />
                <div
                  v-if="data.item.state.status === 'done'"
                  class="statistics text-success"
                >
                  Done
                </div>
              </template>

              <template #cell(state.statistics)="data">
                <div v-if="data.item.state.status !== 'processing'">
                  <ul>
                    <li
                      v-if="data.item.state.statistics.okParcels"
                      v-b-tooltip.hover.left="'Parcels successfully imported.'"
                      class="statistics text-success"
                    >
                      OK: {{ data.item.state.statistics.okParcels }} / {{ data.item.state.statistics.parcels }} parcels
                    </li>
                    <li
                      v-if="data.item.state.statistics.okItems"
                      v-b-tooltip.hover.left="'Parcel items successfully imported.'"
                      class="statistics text-success"
                    >
                      OK: <span v-if="data.item.state.statistics.okItems">{{ data.item.state.statistics.okItems }} / </span>{{ data.item.state.statistics.parcelItems }} parcel items
                    </li>
                    <li
                      v-if="data.item.state.status === 'failed' && data.item.state.error"
                      class="statistics text-danger"
                    >
                      {{ data.item.state.error }}
                    </li>
                    <li
                      v-else-if="data.item.state.status === 'failed'"
                      class="statistics text-danger"
                    >
                      Manifest import failure. Check manifest and try to upload again.
                    </li>
                    <li
                      v-for="(issue, index) in data.item.issues"
                      :key="(index + 1)"
                      v-b-tooltip.hover.left="'Imported item with an issue.'"
                      class="statistics"
                    >
                      <span :class="{'text-danger': issue.type !== 'warning', 'text-warning': issue.type === 'warning'}">
                        <span v-if="issue.issue_code === 'empty'">Empty field '{{ issue.field }}' in {{ issueMappings.tables[issue.table] }} ({{ issue.total }} total)</span>
                        <span v-if="issue.issue_code === 'invalid'">
                          Incorrect value for '{{ issue.field }}' in {{ issueMappings.tables[issue.table] }} ({{ issue.total }} total)
                        </span>
                        <span v-if="issue.issue_code === 'encrypted'">
                          Incorrect value for '{{ issue.field }}' in {{ issueMappings.tables[issue.table] }}
                          (Encryption key might be missing)
                          ({{ issue.total }} total)
                        </span>
                        <span v-if="!['empty', 'invalid', 'encrypted'].includes(issue.issue_code)">Issue ({{ issue.issue_code }}) with field '{{ issue.field }}' in {{ issueMappings.tables[issue.table] }} ({{ issue.total }} total)</span>
                      </span>
                    </li>
                    <li
                      v-for="(error, index) in data.item.state.statistics.errors"
                      :key="(index + 1)"
                      v-b-tooltip.hover.left="'Fatal error that blocked the import.'"
                      class="statistics text-danger"
                    >
                      {{ error.message }}
                    </li>
                    <li
                      v-if="data.item.state.skippedParcels"
                      class="statistics text-info"
                    >
                      {{ data.item.state.skippedParcels }} parcels skipped, already imported
                    </li>
                  </ul>
                </div>
              </template>

              <template
                #cell(id)="data"
              >
                <b-row style="min-width: 300px">
                  <b-col class="col-9 d-flex align-items-center">
                    <span v-if="data.item.state.status !== 'processing' && data.item.issues.length > 0">
                      <b-link :href="`${fileBaseUrl}/repair-files/${data.item.id}`">
                        Repair file
                      </b-link>
                    </span>
                  </b-col>
                  <b-col class="col-3 text-right">
                    <b-tooltip
                      v-if="isPreDeclarationDone"
                      triggers="hover"
                      :target="`delete-file-${data.item.id}`"
                      title="Manifest file delete is not allowed when there are declarations"
                      :delay="{ show: 1000, hide: 50 }"
                    />
                    <b-tooltip
                      v-else-if="data.item.state.status === 'processing'"
                      triggers="hover"
                      :target="`delete-file-${data.item.id}`"
                      title="Manifest file delete is not allowed when it is in processing"
                      :delay="{ show: 1000, hide: 50 }"
                    />
                    <span
                      :id="`delete-file-${data.item.id}`"
                      class="d-inline-block"
                    >
                      <b-button
                        v-if="canEditManifest"
                        v-b-tooltip.hover.left="'Will delete the file, parcels and parcel items.'"
                        :disabled="isPreDeclarationDone || data.item.state.status === 'processing'"
                        :variant="isPreDeclarationDone || data.item.state.status === 'processing' ? 'outline-secondary' : 'outline-danger'"
                        class="btn-icon"
                        @click="deleteFile(data)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <hr class="mt-0">
        <b-row class="shipment-summary">
          <b-col><strong>Shipment Summary</strong></b-col>
          <b-col>
            <div class="d-flex full-width">
              <span class="d-inline-block mr-1">Parcels:</span>
              <b-skeleton
                v-if="summaryLoading"
                width="100px"
              />
              <b-badge v-else>
                {{ summary.parcels }}
              </b-badge>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex full-width">
              <span class="d-inline-block mr-1">Items:</span>
              <b-skeleton
                v-if="summaryLoading"
                width="100px"
              />
              <b-badge v-else>
                {{ summary.items }}
              </b-badge>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex full-width">
              <span class="d-inline-block mr-1">Weight:</span>
              <b-tooltip
                v-if="shipment.document_gross_weight && shipment.document_gross_weight === summary.weight"
                triggers="hover"
                target="weight-tooltip"
                title="Shipment gross weight and items weight are equal"
                :delay="{ show: 1000, hide: 50 }"
              />
              <b-skeleton
                v-if="summaryLoading"
                width="100px"
              />
              <b-badge
                v-else-if="shipment.document_gross_weight && shipment.document_gross_weight === summary.weight"
                id="weight-tooltip"
                variant="light-success"
              >
                {{ shipment.document_gross_weight | formatNumber(3) }} kg
              </b-badge>
              <b-badge
                v-else
                id="weight-tooltip"
              >
                {{ summary.original_weight | formatNumber(3) }} kg
              </b-badge>
              <b-tooltip
                v-if="canUpdateItemsWeight"
                triggers="hover"
                target="shipment-weight-tooltip"
                title="Shipment gross weight"
                :delay="{ show: 1000, hide: 50 }"
              />
              <b-badge
                v-if="canUpdateItemsWeight"
                id="shipment-weight-tooltip"
                class="ml-1"
                variant="danger"
              >
                {{ shipment.document_gross_weight | formatNumber(3) }} kg
              </b-badge>
            </div>
          </b-col>
          <b-col v-if="!isPreDeclarationDone && canManualUpdateWeight && canEditManifest">
            <div class="d-flex full-width">
              <b-button
                :disabled="formBusy || someFileIsProcessing"
                variant="outline-primary"
                size="sm"
                @click="updateWeights"
              >
                <b-spinner
                  v-if="formBusy"
                  small
                />
                <span v-else>Update items weights</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-row
        v-if="canEditManifest && summary.parcels"
        class="mb-2"
      >
        <b-col
          v-if="!isPreDeclarationDone"
          class="text-center"
        >
          <p
            v-if="!canConfirm"
            id="manifest-import-error"
            class="text-danger"
          >
            Manifest parcels {{ totalParcels }} and imported parcels {{ summary.parcels }} do not match, please remove all manifests and import them again
          </p>
          <p
            v-else
            class="text-muted"
          >
            To proceed with declarations please confirm your manifest uploads
          </p>
          <b-button
            id="lock-manifests"
            v-b-modal.close-manifest-modal
            variant="success"
            :disabled="someFileIsProcessing || manifestLockLoading || !canConfirm"
            @click="confirmCloseManifest = true"
          >
            <feather-icon
              v-if="!manifestLockLoading"
              icon="CheckCircleIcon"
            />
            <b-spinner
              v-else
              small
            />
            Confirm manifest upload is done
          </b-button>
        </b-col>
        <b-col
          v-else-if="!shipment.declarations"
          class="text-center"
        >
          <p class="text-muted">
            Do you still need to update the manifests?
          </p>
          <b-button
            id="unlock-manifests"
            variant="success"
            @click="lockManifests(false)"
          >
            <feather-icon
              v-if="!manifestLockLoading"
              icon="UnlockIcon"
            />
            <b-spinner
              v-else
              small
            />
            Unlock manifest editing
          </b-button>
        </b-col>
      </b-row>

      <div v-if="!summaryLoading">
        <declarations-statistics
          v-if="statistics && !shipment.is_export"
          :shipment-id="$attrs.shipmentId"
          :is-h1-allowed="showH1"
          :is-pre-declaration-done="isPreDeclarationDone"
          :statistics="statistics"
          @done="markAsDone"
        />

        <shipment-statuses
          v-if="!shipment.is_export && isPreDeclarationDone"
          :shipment-statistics="shipmentStatistics"
          :shipments-statistics-fields="shipmentsStatisticsFields"
          :shipment-id="$attrs.shipmentId"
          :event-hub="eventHub"
          :is-data-loaded="shipmentStatusesLoaded"
          :show-statuses="this.showStatuses"
        />

        <mind-soft :shipment="shipment" />
        <export-shipment
          v-if="shipment.is_export && shipment.parcels > 0 && isPreDeclarationDone"
          :shipment-id="$attrs.shipmentId"
          @done="markAsDone(true)"
        />

        <ens-form
          v-if="!shipment.is_export && ensCompliant && shipment.parcels > 0 && isPreDeclarationDone"
          :shipment="shipment"
          :statistics="statistics"
        />

        <shipment-audits
          v-if="!shipment.is_export && isPreDeclarationDone"
          :shipment-id="$attrs.shipmentId"
        />
      </div>
    </b-col>
    <b-col
      v-if="isLoaded === false"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BRow,
  BLink,
  BCol,
  BCard,
  BCardText,
  BTable,
  BSpinner,
  VBTooltip,
  BFormFile,
  BProgress,
  BTooltip,
  BBadge,
  BSkeleton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import shippingImportIssues from '@/datasets/ShipmentImportIssues'
import DeclarationsStatistics from '@/views/shipments/DeclarationsStatistics.vue'
import shipmentModel from '@/views/shipments/shipmentModel'
import ShipmentStatuses from '@/views/shipments/cards/ShipmentStatuses.vue'
import ShipmentAudits from '@/views/shipments/cards/ShipmentAudits.vue'
import MindSoft from '@/views/shipments/cards/MindSoft.vue'
import EnsForm from '@/views/shipments/cards/ENSForm.vue'
import ExportShipment from '@/views/shipments/cards/ExportShipment.vue'
import handleError from '@/views/components/errorHandler'
import ConfirmModal from '@core/components/confirmation/confirmModal.vue'
import Vue from 'vue'

export default {
  components: {
    ConfirmModal,
    BTooltip,
    EnsForm,
    ExportShipment,
    ShipmentStatuses,
    ShipmentAudits,
    MindSoft,
    BButton,
    BRow,
    BCol,
    BCard,
    BLink,
    BCardText,
    BTable,
    BSpinner,
    BFormFile,
    BProgress,
    BBadge,
    BSkeleton,
    DeclarationsStatistics,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      eventHub: new Vue(),
      manifestLockLoading: false,
      shipmentStatusesLoaded: false,
      formBusy: false,
      summaryLoading: true,
      summary: {},
      issueMappings: shippingImportIssues,
      isLoaded: false,
      confirmCloseManifest: false,
      uploadFile: null,
      upload: {
        isUploading: false,
        uploadProgress: 0,
      },
      fields: [
        { key: 'data', label: 'Data', tdClass: 'pl-0' },
        { key: 'state.status', label: 'Status', tdClass: 'pl-0' },
        { key: 'state.statistics', label: 'Statistics', tdClass: 'd-none d-sm-table-cell pl-0' },
        { key: 'id', label: 'Actions', tdClass: 'd-none d-sm-table-cell pl-0' },
      ],
      shipment: {},
      statistics: {},
      shipmentsStatisticsFields: [
        'name',
        {
          key: 'available',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'done',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      shipmentStatistics: [
        {
          name: 'Arrived in facility',
          link: `/shipments/${this.$attrs.shipmentId}/event-interface/arrived_to_facility`,
          class: 'text-secondary',
          done: 0,
          available: 0,
        },
        {
          name: 'Ready for last mile',
          link: `/shipments/${this.$attrs.shipmentId}/event-interface/ready_for_last_mile`,
          class: 'text-secondary',
          available: 0,
          done: 0,
        },
        {
          name: 'Released to last mile',
          link: `/shipments/${this.$attrs.shipmentId}/event-interface/released_for_last_mile`,
          class: 'text-secondary',
          available: 0,
          done: 0,
        },
      ],
    }
  },
  computed: {
    showH1() {
      return this.shipment.is_h1_allowed && this.shipment.company?.config?.h1
    },
    showStatuses() {
      return this.shipment.company?.config?.loadShipmentStatuses
    },
    canEditManifest() {
      return this.$permissions().hasPermission('edit shipment')
        || this.$permissions().hasPermission('add manifest')
    },
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    getTemplate() {
      if (['EE'].includes(this.shipment.entry_country) && this.shipment.declaration_type === 'H1') {
        return `/${this.shipment.entry_country.toUpperCase()}_${this.shipment.declaration_type}_template.xlsx`
      }
      if (this.shipment.entry_country === 'LV') {
        return `/${this.shipment.entry_country.toUpperCase()}_template.xlsx`
      }
      return '/template.xlsx'
    },
    confirmManifestText() {
      if (this.canAutoUpdateWeight) {
        return 'This closes option to add or change manifests, calculates weights if assigned to shipment and opens options to start sending declarations. Press it only when you have finished adding or changing manifests.'
      }
      return 'This closes option to add or change manifests and opens options to start sending declarations. Press it only when you have finished adding or changing manifests.'
    },
    confirmManifestButton() {
      if (this.canAutoUpdateWeight) {
        return 'Calculate and confirm'
      }
      return 'Confirm'
    },
    fileBaseUrl() {
      return `${process.env.VUE_APP_API_URL}/v1/companies/${this.companyId}/shipments/${this.$attrs.shipmentId}`
    },
    companyId() {
      return this.shipment?.company_id || this.$activeCompany().data.company.id
    },
    someFileIsProcessing() {
      let isProcessing = false
      if (!this.shipment.files.length && this.summary.parcels === 0) {
        isProcessing = true
      }
      this.shipment.files.forEach(value => {
        if (!['done', 'repair'].includes(value.state.status)) {
          isProcessing = true
        }
      })
      return isProcessing
    },
    totalParcels() {
      return this.shipment.files
        ?.filter(x => x.state.is_repair === false && x.state.status === 'done')
        .reduce((total, file) => total + (file.state.statistics.parcels || 0), 0)
        ?? 0
    },
    canConfirm() {
      return this.shipment.files?.length && !this.someFileIsProcessing ? this.totalParcels === this.summary.parcels : true
    },
    canManualUpdateWeight() {
      return !this.shipment.company?.config?.autoWeightCalculation && this.canUpdateItemsWeight
    },
    canAutoUpdateWeight() {
      return this.shipment.company?.config?.autoWeightCalculation && this.canUpdateItemsWeight
    },
    canUpdateItemsWeight() {
      return !this.summaryLoading
        && this.shipment.files.length
        && this.shipment.document_gross_weight
        && this.shipment.document_gross_weight !== this.summary.weight
    },
    isPreDeclarationDone() {
      return this.shipment.is_pre_declaration_done
    },
    ensCompliant() {
      return this.shipment.document_number
        && this.shipment.origin_airport
        && this.shipment.destination_airport
        && this.shipment.carrier
        && this.shipment.entry_country
    },
  },
  watch: {
    uploadFile() {
      if (this.uploadFile !== null) {
        this.submitFile()
      }
    },
  },
  created() {
    // Pusher handling
    const chnl = this.$pusher.subscribe(`shipment-${this.$attrs.shipmentId}`)

    // Unbind existing events
    chnl.unbind_all()

    // Manifest event
    chnl.bind('manifest', ({ shipmentFile }) => {
      const newList = []
      let found = false
      this.shipment.files.forEach(value => {
        if (value.id !== shipmentFile.id) {
          newList.push(value)
        } else {
          found = true
          newList.push(shipmentFile)
        }
      })
      if (!found) {
        newList.push(shipmentFile)
      }
      this.shipment.files = newList
      if (shipmentFile && shipmentFile.state.status !== 'processing') {
        this.updateStatistics()
        this.getShipment(true)
      } else {
        this.updateSummary()
      }
    })

    // Delete event
    chnl.bind('delete', ({ shipmentFileId }) => {
      const newList = []
      this.shipment.files.forEach(value => {
        if (value.id !== shipmentFileId) {
          newList.push(value)
        }
      })
      this.shipment.files = newList
      this.updateStatistics()
      this.getShipment(true)
    })

    this.getShipmentAsync()
      .then(() => {
        this.updateStatistics()
      })
    this.eventHub.$on('load-shipment-statistics', this.updateShipmentStatistics)

    // Update statistics event
    chnl.bind('refresh-statistics', () => {
      this.updateStatistics()
    })

    // Declaration status changes
    chnl.bind('declaration-status', () => {
      this.updateStatistics()
    })
  },
  beforeDestroy() {
    this.eventHub.$off('load-shipment-statistics', this.updateShipmentStatistics)
  },
  methods: {
    getShipmentAsync() {
      return new Promise((resolve, reject) => {
        try {
          this.getShipment()
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },
    updateWeights() {
      this.formBusy = true
      return this.$http.post(`/v1/companies/${this.companyId}/shipments/${this.$attrs.shipmentId}/update-weights`)
        .then(() => {
          this.updateSummary()
          this.formBusy = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Weights successfully calculated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.formBusy = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to calculate weights, please try again',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
          return Promise.reject()
        })
    },
    lockManifests(isLocked) {
      this.closeManifests(isLocked)
    },
    closeManifests(isLocked) {
      this.manifestLockLoading = true
      this.$http.post(
        `/v1/companies/${this.companyId}/shipments/${this.$attrs.shipmentId}/close-manifests`,
        { locked: isLocked },
      )
        .then(() => {
          this.$bvModal.hide('close-manifest-modal')
          this.confirmCloseManifest = !isLocked
          this.getShipment(true)
          if (isLocked) {
            this.updateStatistics()
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Manifests are ${isLocked ? 'locked' : 'unlocked'} successfully`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .finally(() => {
          this.manifestLockLoading = false
        })
    },
    showProgress() {
      return this.upload.isUploading
    },
    getShipment(noCache = false) {
      if (!this.dataLoading) {
        const cachedData = shipmentModel().getCachedShipment(this.$attrs.shipmentId)
        if (cachedData === null || noCache) {
          shipmentModel().getShipment(this.$attrs.shipmentId, this)
            .then(response => {
              shipmentModel().cacheShipment(response.data)
              this.dataUpdate(response.data)
            })
        } else {
          this.dataUpdate(cachedData)
        }
        this.updateSummary()
      }
    },
    dataUpdate(data) {
      this.isLoaded = true
      this.shipment = data

      const shipmentIdentity = this.shipment.document_number

      this.$route.meta.pageTitle = `Shipment (${shipmentIdentity})`
      this.$globalEvent.$emit('update-page-title', this.$route.meta.pageTitle)

      if (this.$profile().data.user.companies.length > 1) {
        this.$route.meta.company = this.shipment.company
        this.$globalEvent.$emit('update-page-company', this.$route.meta.company)
      }
    },
    submitFile() {
      const formData = new FormData()
      formData.append('file', this.uploadFile)
      this.upload.isUploading = true
      this.upload.uploadProgress = 0
      this.$http.post(`/v1/companies/${this.companyId}/shipments/${this.$attrs.shipmentId}/files`,
        formData,
        {
          // eslint-disable-next-line func-names
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this.upload.uploadProgress = percentCompleted

            return percentCompleted
          }.bind(this),
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.upload.isUploading = false
          this.uploadFile = null
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File successfully imported',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.uploadFile = null
          this.upload.isUploading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to import file',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteFile(data) {
      this.$http.delete(`/v1/companies/${this.companyId}/shipments/${this.$attrs.shipmentId}/files/${data.item.id}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File successfully removed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to remove file',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateShipmentStatistics() {
      this.$http.get(`/v1/api/companies/${this.companyId}/shipments/${this.$attrs.shipmentId}/events/statistics`)
        .then(response => {
          this.shipmentStatusesLoaded = true
          this.shipmentStatistics[0].available = response.data.data[0].available
          this.shipmentStatistics[0].done = response.data.data[0].done
          this.shipmentStatistics[1].available = response.data.data[1].available
          this.shipmentStatistics[1].done = response.data.data[1].done
          this.shipmentStatistics[2].available = response.data.data[2].available
          this.shipmentStatistics[2].done = response.data.data[2].done
        })
    },
    updateSummary() {
      this.summaryLoading = true
      this.$http.get(`/v1/companies/${this.companyId}/shipments/${this.$attrs.shipmentId}/summary`)
        .then(response => {
          this.summary.parcels = response.data?.statistics?.total_parcels || 0
          this.summary.items = response.data?.statistics?.total_parcel_items || 0
          this.summary.weight = response.data?.statistics?.total_parcels_weight || 0
          this.summary.original_weight = response.data?.statistics?.total_parcels_original_weight || 0
        })
        .finally(() => {
          this.summaryLoading = false
        })
    },
    updateStatistics() {
      this.$http.get(`/v1/companies/${this.companyId}/shipments/${this.$attrs.shipmentId}/declarations/statistics`)
        .then(response => {
          const statistics = {}
          statistics.buyerCountryNotAllowed = { h7: response.data.data.buyer_country_not_allowed }
          statistics.cleared = { h7: response.data.data.cleared }
          statistics.heldByCustoms = { h7: response.data.data.held_by_customs }
          statistics.needsAttention = { h7: response.data.data.needs_attention }
          statistics.notSent = { h7: response.data.data.not_sent }
          statistics.priceOver = { h7: response.data.data.price_over }
          statistics.prohibited = { h7: response.data.data.prohibited }
          statistics.reducedVat = { h7: response.data.data.reduced_vat }
          statistics.waitingForArrival = { h7: response.data.data.waiting_for_arrival }
          statistics.waitingForCustoms = { h7: response.data.data.waiting_for_customs }
          statistics.preparingToSend = { h7: response.data.data.preparing_to_send }
          statistics.cancelled = { h7: response.data.data.cancelled }
          statistics.resendables = { h7: response.data.data.resendables }
          statistics.needsDocuments = { h7: response.data.data.needs_documents }
          statistics.supplementaryUnit = { h7: response.data.data.supplementary_units }
          if ('not_sent_h1' in (response.data.data || {})) {
            statistics.notSent.h1 = response.data.data.not_sent_h1
            statistics.preparingToSend.h1 = response.data.data.preparing_to_send_h1
            statistics.waitingForCustoms.h1 = response.data.data.waiting_for_customs_h1
            statistics.needsAttention.h1 = response.data.data.needs_attention_h1
            statistics.draftCreated = { h1: response.data.data.draft_created_h1 }
            statistics.cleared.h1 = response.data.data.cleared_h1
          }
          this.statistics = {
            ...this.statistics,
            ...statistics,
          }
        })
      this.$http.get(`/v1/companies/${this.companyId}/shipments/${this.$attrs.shipmentId}/missing-hs-codes/statistics`)
        .then(response => {
          const statistics = {}
          this.statistics.missingHsCodes = {}
          response.data.data.missing_hs_codes.forEach(missingHsCode => {
            const dec = missingHsCode.declaration_type.toLowerCase()
            statistics.missingHsCodes = statistics.missingHsCodes || {}
            statistics.missingHsCodes[dec] = missingHsCode?.missing_hs_count || 0
          })
          this.statistics = {
            ...this.statistics,
            ...statistics,
          }
        })
    },
    markAsDone(reloadShipment = false) {
      if (this.shipment.status === 'done') {
        if (reloadShipment) {
          shipmentModel().clearCache(this.$attrs.shipmentId)
          this.getShipment()
        } else {
          this.$router.push({ name: 'shipments' })
        }
        return
      }
      this.$http.get(`/v1/companies/${this.companyId}/shipments/${this.$attrs.shipmentId}/done`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Shipment marked as done',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          if (reloadShipment) {
            shipmentModel().clearCache(this.$attrs.shipmentId)
            this.getShipment()
          } else {
            this.$router.push({ name: 'shipments' })
          }
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.statistics {
  font-weight: bold;
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>
